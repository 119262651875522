import { Article } from "utils/datautils"

const data: Article[] = [
  {
    "article_date": "22 Jul 2022",
    "img": "/assets/articles/article_home_5.jpeg",
    "meta_img": "https://drive.google.com/uc?export=view&id=1Bf6GuJrXupe6pzDBkiXbUMUu50zClEK5",
    "date": "22 Jul 2022",
    "date_2": "22 Jul 2022",
    "title": "Making Indonesia 4.0, Visi Indonesia Untuk Revolusi Industri 4.0",
    "description": "Melalui Making Indonesia 4.0, Kementerian Perindustrian RI fokus pada penerapan revolusi industri 4.0 demi meningkatkan daya saing dan pemulihan ekonomi nasional.",
    "description_home": "Melalui Making Indonesia 4.0, Kementerian Perindustrian RI fokus pada penerapan revolusi industri 4.0 demi meningkatkan daya saing dan pemulihan ekonomi nasional.",
    "duration": "dibaca 4 menit",
    "author": "oleh Evira Novitasari",
    "slug": "making-indonesia-4-0-visi-indonesia-untuk-revolusi-industrti-4-0",
    "text": [
      `<p>Saat ini, pembangunan industri dihadapkan berbagai tantangan dan persaingan dari segala arah. Tiga tahun pandemi Covid-19 di Indonesia hingga persaingan global, menjadi tantangan yang harus dihadapi untuk mengimplementasikan pembangunan industri di Indonesia. Memasuki era industri 4.0, pemerintah menjadikan penerapan industri 4.0 sebagai sektor prioritas dalam mencapai pemulihan ekonomi nasional berkelanjutan.</p>`,
      `<p>Melalui webinar Hukumonline Compliance Talks #5 pada Jumat (01/07) lalu, Staf Ahli Menteri Bidang Iklim Usaha dan Investasi Kementerian Perindustrian RI, Andi Rizaldi dan Sekretaris Umum Gabungan Industri Kendaraan Bermotor Indonesia (GAIKINDO), Kukuh Kumara, memaparkan potensi penerapan revolusi industri 4.0 di Indonesia.</p>`,
      `<p>Andi Rizaldi mengungkapkan, dengan berbagai tantangan yang ada, dibutuhkan dukungan kebijakan pemerintah secara holistik sebagai pilar demi keberhasilan inisiatif industri 4.0. Beberapa aspek yang menjadi pilar utama penerapan industri 4.0 adalah ketersediaan dan kualitas infrastruktur digital; kesiapan regulasi; ketersediaan sumber daya manusia yang unggul; akses permodalan; dan kematangan konsumen.</p>`,
      `<p>Pemerintah menyiapkan tagline ‘Making Indonesia 4.0’ sebagai visi nasional dalam menerapkan revolusi industri 4.0 di Indonesia. Inisiatif ‘Making Indonesia 4.0’ sendiri menjadi wadah dalam mencapai aspirasi Indonesia sebagai sepuluh negara ekonomi terbaik di tahun 2030. Melalui tagline tersebut, diharapkan dapat membangkitkan semangat semua stakeholder terkait dalam memprioritaskan implementasi industri 4.0.</p>`,
      `<p>Visi dan pencapaian tersebut diimplementasikan melalui agenda strategi prioritas yang menyasar kepada sektor otomotif, makanan dan minuman, kimia, tekstil dan produk tekstil, elektronika, farmasi, dan alat kesehatan. </p>`,
      `<p>Penerapan inisiatif Making Indonesia 4.0 sendiri terbagi menjadi beberapa tahapan, yaitu memperkuat industri fundamental hingga 2021; memperkuat keterlibatan lintas sektor yang ditargetkan akan tercapai pada 2024; dan memperkuat keterlibatan di pasar global yang ditargetkan akan tercapai pada 2030.</p>`,
      `<div style="width: 100%; max-width:100%; text-align: center">
        <img src="/assets/articles/article_home_5_content.png" />
      </div>`,
      `<p style="font-size: 0.6em; text-align: center">Sumber: materi Andi Rizaldi, Staf Ahli Menteri Bidang Iklim Usaha dan Investasi Kementerian Perindustrian RI.</p>`,
      `<p>Mengingat dibutuhkannya dukungan regulasi secara holistik demi tercapainya industri 4.0, pemerintah menyelaraskan pelaksanaan inisiatif ‘Making Indonesia 4.0’ dengan menerbitkan beberapa dasar hukum, sebagai berikut:</p>`,
      `
        <ol>
          <li>Peraturan Presiden No. 18 tahun 2020 tentang Rencana Pembangunan Jangka Menengah Nasional (RPJMN) Tahun 2020-2024, yang menekankan bahwa implementasi revolusi industri 4.0 sebagai major project RPJMN yang harus diprioritaskan pada tahun 2020-2024;</li>
          <li>Peraturan Presiden No. 74 tahun 2022 tentang Kebijakan Industri Nasional Tahun 2020-2024;</li>
          <li>Peraturan Menteri Perindustrian No. 21 Tahun 2020, mengatur tentang pengukuran kesiapan industri dalam bertransformasi menuju Industri 4.0; dan</li>
          <li><i>Draft</i> Revisi Peraturan Pemerintah No. 14 Tahun 2015 tentang Rencana Induk Pembangunan Industri Nasional (RIPIN) Tahun 2015-2035.</li>
        </ol>
      `,
      `<p>Pada kesempatan yang sama, Sekretaris Umum Gabungan Industri Kendaraan Bermotor Indonesia (GAIKINDO), Kukuh Kumara menyampaikan bahwa selaku pelaku usaha, salah satu tantangan yang dihadapi industri manufaktur otomotif selama pandemi Covid-19 adalah ekosistem industri otomotif yang tidak sederhana.</p>`,
      `<p>Tanpa pemasok komponen, industri otomotif tidak dapat berfungsi dan menghasilkan kendaraan untuk kebutuhan dalam negeri maupun ekspor. Sedangkan, pada saat krisis pandemi Covid-19, perusahaan pemasok komponen berada pada level tier 2,3, hingga UMKM. Oleh karenanya, aktivitas pengadaan pasokan komponen sempat terhambat selama pandemi.</p>`,
      `<p>Membahas terkait kondisi industri kendaraan bermotor saat ini, Kukuh Kumara mengatakan, Indonesia saat ini menempati urutan ke-15 di dunia dalam produksi kendaraan bermotor. Meskipun Industri otomotif di Indonesia sempat mengalami kontraksi selama pandemi Covid-19, tetapi berhasil menunjukkan kondisi perbaikan yang signifikan di tahun 2021.</p>`,
      `<p>Melihat masa depan industri kendaraan bermotor Indonesia, berbagai negara telah menyiapkan target tahun 2025, di mana pendapatan per kapita mencapai sebesar $10.000 hingga $20.000. Jumlah ini menambah kebutuhan masyarakat atas kendaraan bermotor khususnya kendaraan LCGC (Low Cost Green Car).</p>`,
      `<p>Di sisi lain, tambah Kukuh Kumara, diharapkan munculnya teknologi terbaru untuk meningkatkan digitalisasi dalam industri kendaraan bermotor. Untuk mendukung konsep Making Indonesia 4.0, secara bertahap industri kendaraan bermotor berupaya untuk merambah ke tren baru dan menghadirkan kendaraan ramah lingkungan. Hal ini sebagai upaya untuk menekan emisi gas buang yang bersumber dari kendaraan bermotor.</p>`,
      `<p>Agar tren green mobility atau yang dikenal sebagai kendaraan ramah lingkungan dapat terimplementasi dengan baik, dibutuhkan dukungan secara holistik baik dari pemanfaatan teknologi hingga dukungan pemerintah. Industri kendaraan bermotor berupaya untuk mengikuti visi pemerintah dengan mengikuti standar emisi gas buang yang lebih baik.</p>`,
      `<p>Revolusi industri 4.0 pada industri otomotif dipandang Kukuh Kumara sebagai hal yang tidak terelakkan, dikarenakan industri 4.0 mengarah pada proses automasi dalam bisnis. Dengan transformasi digital dalam industri kendaraan bermotor, supply chain industri otomotif dapat dilakukan secara real-time dan terintegrasi.</p>`,
      `<p>Dengan memanfaatkan teknologi dalam aktivitas industri, dapat memberikan berbagai kemudahan untuk meningkatkan performa perusahaan. Dinamisnya perkembangan regulasi sering kali menjadi tantangan bagi pelaku usaha untuk selalu memperbarui kewajiban hukum terkini. Oleh karenanya, Hukumonline mengembangkan <b>Regulatory Compliance System (RCS)</b>, solusi pemantauan kepatuhan hukum berbasis Artificial Intelligence (AI) agar perusahaan Anda senantiasa memperbarui kewajiban hukum perusahaan sesuai dengan kebijakan terkini.</p>`,
      `<p><b>Regulatory Compliance System (RCS)</b> dilengkapi berbagai fitur agar seluruh aktivitas pemantauan kepatuhan hingga pelaporan pemenuhan kewajiban hukum dapat dilakukan terintegrasi. Anda dapat mengajukan demo secara gratis <a href="https://rcs.hukumonline.com/wa?utm_source=website&utm_medium=website_rcs_advertorial&utm_campaign=website_rcs_advertorial_hct5" style="color: #1155CC">disini</a> untuk merasakan secara langsung manfaat dari <b>Regulatory Compliance System.</b></p>`,
    ]
  },
  {
    "article_date": "02-03-2022",
    "img": "/assets/articles/article_home_1.jpeg",
    "meta_img":"https://drive.google.com/uc?export=view&id=1dRiS74Rh9vSNEbhOitkqkfUSaca2ZkxS",
    "title": "Penerbitan dan Pendaftaran Izin Usaha Pertambangan  (IUP) Berdasarkan Putusan PTUN dan Laporan Lembaga Negara",
    "date": "03 Feb 2022",
    "date_2": "03 Feb 2022",
    "description": "Eksistensi izin dalam pelaksanaan kegiatan usaha menjadi aspek yuridis yang sangat diperlukan karena berkaitan dengan keabsahan/legalitas suatu usaha.",
    "description_home": "Eksistensi izin dalam pelaksanaan kegiatan usaha menjadi aspek yuridis yang sangat diperlukan karena berkaitan dengan keabsahan/legalitas suatu usaha.",
    "duration": "dibaca 5 menit",
    "author": "oleh Aditiya Putra",
    "slug": "penerbitan-dan-pendaftaran-izin-usaha-pertambangan-iup-berdasarkan-putusan-ptun-dan-laporan-lembaga-negara",
    "text": [
      `<p>Dalam usaha pertambangan misalnya, Izin Usaha Pertambangan (IUP) adalah izin usaha yang diperlukan. Demi menjamin keadilan dan kepastian hukum bagi pelaku usaha, temasuk halnya pelaku usaha di sektor petambangan, pendaftaran izin usaha pertambangan yang dimaksud tentu perlu diatur secara detail dan jelas.</p>`,
      `<p>Berkaitan dengan hal tersebut, nyatanya Menteri Energi dan Sumber Daya Mineral (Menteri ESDM) telah menerbitkan regulasi berupa <a href="https://www.hukumonline.com/pusatdata/detail/lt61f395cd3218b/keputusan-menteri-energi-dan-sumber-daya-mineral-nomor-15k-hk02-memb-2022-tahun-2022?r=0&p=1&q=15.K%2FHK.02%2FMEM.B%2F2022&rs=1847&re=2022#!">Keputusan No.15.K/HK.02/MEM.B/2022 tentang Tata Cara Pemrosesan Penerbitan dan Pendaftaran Izin Usaha Pertambangan</a> (Kepmen ESDM 15/2022). Adapun keputusan menteri tersebut memberikan ruang  bagi pelaku usaha pemegang Izin Usaha Pertambangan (IUP), khususnya pemegang IUP yang ingin melanjutkan prosedur perizinan berdasarkan putusan pengadilan atau keputusan yang diterbitkan oleh instansi terkait. Sebagai informasi, regulasi tersebut secara garis besar mengatur empat aspek, yaitu:<sup>1</sup></p>`,
      `
        <ol>
          <li>Pemberian IUP berdasarkan putusan PTUN;</li>
          <li>Pemberian IUP berdasarkan Laporan Lembaga Negara;</li>
          <li>Pembatalan dan pembekuan status IUP terdaftar; dan</li>
          <li>Pengurusan pemegang IUP yang telah dinyatakan pailit.</li>
        </ol>
      `,
      `
        <p>Untuk pembahasan kali ini, penulis akan lebih mencermati ketentuan dalam Kepmen ESDM 15/2022 yang berkaitan dengan pemberian IUP berdasarkan putusan PTUN dan laporan lembaga negara, serta pembatalan dan pembekuan status IUP terdaftar.</p>
        <p><b>Pemberian IUP berdasarkan Putusan PTUN</b></p>
        <p>Permohonan penerbitan IUP dapat didasarkan atas putusan Peradilan Tata Usaha Negara (PTUN). Putusan PTUN yang dimaksud dalam konteks ini ialah putusan PTUN dalam dua kondisi. Kondisi pertama, Putusan PTUN yang objeknya terkait keputusan tentang hal-hal sebagai berikut:</p>
          <ol>
            <li>Keputusan terkait pencabutan IUP mineral logam dan batu bara;</li>
            <li>Keputusan terkait penolakan permohonan peningkatan tahapan; dan</li>
            <li>Keputusan terkait penolakan permohonan perpanjangan IUP.</li>
          </ol>
      `,
      `
        <p>Kemudian untuk kondisi kedua, Putusan PTUN terkait pemegang IUP mineral logam atau batubara yang mengalami permasalahan tumpang tindih Wilayah IUP (WIUP).</p>
        <p>Bila dicermati, Kepmen ESDM 15/2022 memberikan nuansa berbeda khususnya terkait dengan persyaratan untuk mengajukan permohonan penerbitan IUP-nya. Untuk kondisi pertama, pelaku usaha dapat mengajukan permohonan penerbitan IUP mineral logam atau batubara kepada Menteri ESDM melalui Direktur Jendral Mineral dan Batubara sepanjang memenuhi persyaratan sebagai berikut:<sup>2</sup> </p>
        <ol>
          <li style="list-style-type: lower-alpha">amar putusan menyatakan sah atau tidak sahnya keputusan tata usaha negara dan/atau memerintahkan untuk membatalkan/mencabut atau menerbitkan perizinan.</li>
          <li style="list-style-type: lower-alpha">memenuhi persyaratan administratif serta persyaratan dan kriteria kewilayahan. </li>
          <li style="list-style-type: lower-alpha">dapat dilakukan pendaftaran IUP mineral logam atau batubara setelah memenuhi persyaratan teknis, lingkungan, dan finansial.</li>
        </ol>
      `,
      `
        <p>Sedangkan untuk kondisi kedua, pelaku usaha dapat mengajukan permohonan penerbitan IUP mineral logam atau batubara kepada Menteri ESDM melalui Direktur Jenderal Mineral dan Batubara sepanjang memenuhi persyaratan yang ditentukan sebagai berikut:<sup>3</sup> </p>
          <ol>
            <li style="list-style-type: lower-alpha">amar putusan menyatakan sah atau tidak sahnya keputusan tata usaha negara atau memerintahkan untuk memproses pendaftaran IUP; dan </li>
            <li style="list-style-type: lower-alpha">memenuhi persyaratan administratif, persyaratan dan kriteria kewilayahan, persyaratan teknis, lingkungan, dan finansial.</li>
          </ol>
      `,
      `
        <p>Dalam hal munculnya dua putusan PTUN yang bersifat <i>inkracht</i> atau berkekuatan hukum tetap, baik dalam konteks kondisi pertama maupun kondisi kedua, Direktur Jenderal Mineral dan batu bara atas nama Menteri ESDM dapat melakukan penyusutan WIUP jika sebagian WIUP tumpang tindih atau pendaftaran IUP mineral logam atau batu bara kepada salah satu Badan Usaha jika seluruh WIUP tumpang tindih dengan menerapkan sistem permohonan pertama pencadangan wilayah yang telah memenuhi persyaratan ( <i>first come first served</i> ) dan mencabut IUP pihak lainnya.<sup>4</sup> Selain itu Direktur Jenderal Mineral dan batu bara dapat melakukan penyelesaian lain dengan memperhatikan asas kemanfaatan, keterbukaan, keadilan, dan kepentingan nasional dan/atau daerah.</p>
      `,
      `
        <p><b>Pemberian IUP berdasarkan laporan Lembaga Negara</b></p>
        <p>Dalam hal pemberian IUP dilakukan berdasarkan laporan Lembaga Negara, perlu dicermati bahwa laporan yang dimaksud merupakan Laporan akhir dari Lembaga Negara yang memiliki kewenangan untuk mengawasi penyelenggaraan pelayanan publik (laporan Lembaga Negara). Adapun laporan tersebut dapat menjadi dasar permohonan IUP mineral logam atau batubara kepada Menteri ESDM melalui Direktur Jenderal Mineral dan Batubara.</p>
        <p>Untuk memperoleh laporan tersebut dari Lembaga Negara, terdapat dua skenario yang dapat diambil oleh pelaku usaha berdasarkan Kepmen ESDM 15/2022, yaitu:</li>
        <ol>
          <li>Dalam hal Badan Usaha yang tidak memperoleh pelayanan perizinan sampai dengan jangka waktu IUP-nya berakhir.<sup>5</sup> (Kondisi 1)</p>
          <li>Dalam hal pemegang IUP mineral logam atau batubara yang tidak memperoleh pelayanan perizinan selama masa pendaftaran.<sup>6</sup> (Kondisi 2)</p>
        </ol>
        <p>Untuk dapat mengimplementasikan kedua kondisi di atas, terdapat persyaratan yang perlu diperhatikan oleh pelaku usaha dari masing masing pendekatan tersebut.:<sup>7</sup></p>
        
      `,
      `table 1`,
      `
        <p><b>Pembatalan dan Penangguhan IUP</b></p>
        <p>Selain mengatur prosedur penerbitan dan pendaftaran IUP sebagaimana telah diuraikan diatas, Kepmen 15/2022 juga memuat ketentuan berkaitan dengan pembatalan dan pembekuan IUP terdaftar. Tindakan administratif tersebut merupakan kewenangan dari Menteri ESDM c.q Direktur Jenderal Mineral dan Batubara sesuai dengan asas atau prinsip yang sama yaitu <i>asas contrarius actus</i>.<sup>8</sup> Akan tetapi perlu dicatat bahwa konteks pembatalan dan pencabutan ini memiliki perbedaan sebagai berikut: </p>
      `,
      `table 2`,
      `
        <p>Sebagai tambahan, keberlakuan Kepmen ESDM 12/2022 mencabut Keputusan Direktur Jenderal Mineral dan Batubara No. 183.K/30/DJB/2020 tentang Tata Cara Pendaftaran Izin Usaha Pertambangan dan Izin Usaha Pertambangan Khusus Berdasarkan Putusan Pengadilan atau Lembaga Terkait Yang Berwenang.<sup>9</sup></p>    
        
        <p><i>
        Izin Usaha Pertambangan adalah salah satu bentuk kewajiban hukum yang wajib dipenuhi oleh perusahaan atau pelaku usaha pertambangan. Untuk memudahkan Anda memenuhi kewajiban hukum lain di industri pertambangan, saatnya gunakan Regulatory Compliance System (RCS) persembahan Hukumonline.
        </i></p>

        <p>
          <i>RCS dilengkapi sejumlah fitur yang memudahkan proses pemantauan kepatuhan hukum. Di antaranya, <b>obligation checklist</b> yang memungkinkan pendataan kewajiban hukum dilakukan lebih cepat.</i>
        </p>

        <p>
          <i>Saatnya permudah pemantauan kepatuhan hukum perusahaan dengan RCS. Klik <a href="https://rcs.hukumonline.com/?utm_source=website&utm_medium=seo_articles&utm_campaign=cta_iup" style="color: #1155CC">di sini</a> untuk menjadwalkan demo <b>GRATIS!</b></i>
        </p>
        
        <ul>
          <p style="font-size: 12px;">1. Bagian Menimbang Kepmen ESDM 15/2022</p>
          <p style="font-size: 12px;">2. Diktum I, Kepmen ESDM 15/2022</p>
          <p style="font-size: 12px;">3. Diktum VII, Kepmen ESDM 15/2022</p>
          <p style="font-size: 12px;">4. Diktum II dan VIII, Kepmen ESDM 15/2022</p>
          <p style="font-size: 12px;">5. Diktum IV, Kepmen ESDM 15/2022</p>
          <p style="font-size: 12px;">6. Diktum IX, Kepmen ESDM 15/2022</p>
          <p style="font-size: 12px;">7. Diktum IX dan XI, Kepmen ESDM 15/2022</p>
          <p style="font-size: 12px;">8. Untuk memahami asas contrarius actum lihat artikel berikut ini, <a href="https://www.hukumonline.com/klinik/a/arti-asas-icontrarius-actus-i-lt5a4091a9d6c08" style="color: #1155CC; text-decoration: underline; font-weight: bold;">Arti Asas Contrarius Actus - Klinik Hukumonline</a></p>
          <p style="font-size: 12px;">9. Diktum XIX, Kepmen ESDM 15/2022</p>
        </ul>
      `
    ],
    // `<p>
    //   Demi menjaga kepatuhan perusahaan, divisi HSE seyogyanya memantau regulasi secara berkala. Salah satu regulasi yang dipantau adalah Undang-Undang Nomor 32 Tahun 2009 tentang Perlindungan dan Pengelolaan Lingkungan hidup (UU 32/2009). Kewajiban-kewajiban yang berasal dari UU 32/2009 biasanya merupakan kewajiban yang memiliki prioritas tinggi dalam perusahaan; mengingat  kewajiban-kewajiban tersebut diikuti sanksi pidana untuk korporasi, seperti sanksi pidana terhadap pelanggaran baku mutu, pengelolaan limbah, hingga aspek perizinan.
    // </p>
    // <p>
    //   Sayangnya, pemantauan regulasi yang dilakukan divisi HSE sering kali memiliki tantangan dan kesulitan tersendiri. Sebagai contoh, pemantauan regulasi secara manual–dilakukan dengan menginput daftar peraturan dan merinci kewajiban perusahaan menggunakan Microsoft Excel–dapat saja berisiko human errorsalah input; hingga alokasi waktu dan tenaga yang inefisien.
    // </p>
    // <p>
    //   Di sisi lain, divisi HSE tidak dapat melihat update secara real time. Pasalnya, selain kewajiban yang berdasarkan peraturan, ada pula kewajiban berdasarkan suatu perizinan tertentu. Ini belum termasuk minimnya sumber daya dalam divisi HSE yang kadang kala menjadi masalah dalam pemantauan dan pembaruan regulasi hingga level kewajiban.
    // </p>
    // <p>
    //   Sebagai contoh, penerbitan Undang-Undang Cipta Kerja (UU Ciptaker)berdampak pada perubahan banyak ketentuan dalam UU 32/2009. UU Ciptaker melahirkan peraturan pemerintah baru terkait perubahan UU 32/2009, yakni Peraturan Pemerintah Nomor 22 Tahun 2021 tentang Penyelenggaraan perlindungan dan Pengelolaan Lingkungan Hidup (PP 22/2021). Pembaruan akibat terbitnya peraturan ini berdampak pada 180 Kewajiban yang diubah, 228 Kewajiban yang baru, dan 39 kewajiban yang dicabut.
    // </p>
    // <p>
    //   Sebagai contoh, penerbitan Undang-Undang Cipta Kerja (UU Ciptaker)berdampak pada perubahan banyak ketentuan dalam UU 32/2009. UU Ciptaker melahirkan peraturan pemerintah baru terkait perubahan UU 32/2009, yakni Peraturan Pemerintah Nomor 22 Tahun 2021 tentang Penyelenggaraan perlindungan dan Pengelolaan Lingkungan Hidup (PP 22/2021). Pembaruan akibat terbitnya peraturan ini berdampak pada 180 Kewajiban yang diubah, 228 Kewajiban yang baru, dan 39 kewajiban yang dicabut.
    // </p>
    // <p>
    //   Selanjutnya, PP 22/2021 melatarbelakangi lahirnya peraturan-peraturan berikut:
    //   <ol type="1">
    //     <li>
    //       Peraturan Menteri Lingkungan Hidup dan Kehutanan Nomor 4 Tahun 2021 tentang Daftar Usaha dan/atau Kegiatan yang Wajib Memiliki Analisis Mengenai Dampak Lingkungan Hidup, Upaya Pengelolaan Lingkungan Hidup dan Upaya Pemantauan Lingkungan Hidup atau Surat Pernyataan Kesanggupan Pengelolaan dan Pemantauan Lingkungan Hidup.
    //     </li>
    //     <li>
    //       Peraturan Menteri Lingkungan Hidup dan Kehutanan Nomor 5 Tahun 2021 tentang Tata Cara Penerbitan Persetujuan Teknis dan Surat Kelayakan Operasional Bidang Pengendalian Pencemaran Lingkungan.
    //     </li>
    //     <li>
    //       Peraturan Menteri Lingkungan Hidup dan Kehutanan Nomor 6 Tahun 2021 tentang Tata Cara dan Persyaratan Pengelolaan Limbah Bahan Berbahaya dan Beracun.
    //     </li>
    //     <li>
    //       Peraturan Menteri Lingkungan Hidup dan Kehutanan Nomor 11 Tahun 2021 tentang Baku Mutu Emisi mesin dengan Pembakaran Dalam.
    //     </li>
    //     <li>
    //       Peraturan Menteri Lingkungan Hidup dan Kehutanan Nomor 12 Tahun 2021 tentang Baku Mutu Emisi Daur Ulang Baterai Lithium.
    //     </li>
    //     <li>
    //       Peraturan Menteri Lingkungan Hidup dan Kehutanan Nomor 13 Tahun 2021 tentang Sistem Informasi Pemantauan Emisi Industri Secara Terus-Menerus
    //     </li>
    //   </ol>
    // </p>
    // <p>
    //   Dengan lahirnya peraturan-peraturan pelaksana PP 22/2021, akan terdapat banyak kewajiban baru, kewajiban yang diubah, atau kewajiban yang dicabut. Segala perubahan tersebut harus diaplikasikan oleh seluruh perusahaan yang terdampak.r
    // </p>
    // <p>
    //   Belum selesai dengan penyesuaian UU Ciptaker dan peraturan pelaksananya, pada November 2021 Mahkamah Konstitusi (MK) melalui Putusan Nomor 91/PUU-XVIII/2020 menyatakan bahwa UU Ciptaker inkonstitusionalitas bersyarat. MK menyatakan, UU Ciptaker masih tetap berlaku secara bersyarat. Pemerintah diberikan waktu selama dua tahun untuk memperbaikinya, dan dilarang untuk menerbitkan peraturan turunan baru untuk sementara waktu. Berarti maksimal dua tahun sejak November 2021, akan terdapat perbaikan dari UU Ciptaker. Perbaikan tersebut pastinya berdampak kepada berbagai industri, termasuk industri ekstraktif. Divisi HSE diharapkan dapat memperbarui kembali daftar peraturan yang berkaitan dengan bisnis perusahaan; sekaligus merinci kewajiban perusahaan berdasarkan peraturan-peraturan baru yang relevan. Ekspektasi besar yang diberikan perusahaan kepada divisi HSE tentunya semakin sulit mengingat seluruh pekerjaan tersebut tidak jarang dilakukan secara manual dan berkala. Sesuai dengan komitmen kami selama lebih dari dua dekade untuk selalu menjadi bagian dari solusi, Hukumonline kini membangun Regulatory Compliance System (RCS), platform karya anak bangsa berbasis AI yang dapat menjadi solusi dari permasalahan atau kesulitan HSE dalam melakukan pemantauan regulasi sampai dengan pemetaan kewajiban-kewajiban hukum sesuai dengan scope atau lini bisnisnya. Fitur Automatic Content Update dalam RCS memudahkan divisi HSE untuk selalu meng-update perubahan regulasi hingga ekstraksi kewajiban yang bersifat dinamis. 
    // </p>
    // <p>
    //   Namun, tidak hanya level kewajiban. Terkadang, suatu kewajiban perizinan memuat beberapa actionable items yang harus dilakukan terlebih dahulu oleh para  PIC kewajiban tersebut. Itu sebabnya,  fitur Obligation Checklist juga dapat digunakan untuk mempermudah merinci items tersebut.
    // </p>
    // <p>
    //   Selain itu divisi HSE juga dapat memonitor pemenuhan kewajiban tersebut dengan fitur Compliance Monitor dari RCS.
    // </p>
    // `,
  },
  {
    "img": "/assets/articles/article_home_2.jpeg",
    "article_date": "01-01-2022",
    "meta_img": "https://drive.google.com/uc?export=view&id=1hJj0oI7Fs5qOll1EHuGIRub0EoCDl8nW",
    "title": "Permen ESDM 35/2021: Nafas Baru Ketentuan Penetapan dan Penawaran Wilayah Kerja Minyak dan Gas Bumi",
    "date": "02 Jan 2022",
    "date_2": "02 Jan 2022",
    // "description": "Menjelang akhir Desember 2021 lalu, Menteri Energi dan Sumber Daya Mineral (“Menteri ESDM”) merevisi aturan tata cara penetapan dan penawaran wilayah kerja migas (minyak dan gas bumi) dengan menerbitkan Peraturan Menteri ESDM Nomor 35 Tahun 2021 tentang Tata Cara Penetapan dan Penawaran (“Permen ESDM 35/2021”).",
    "description_home": "Menjelang akhir Desember 2021 lalu, Menteri Energi dan Sumber Daya Mineral (“Menteri ESDM”) merevisi aturan tata cara penetapan dan penawaran wilayah kerja migas (minyak dan gas bumi) dengan menerbitkan Peraturan Menteri ESDM Nomor 35 Tahun 2021 tentang Tata Cara Penetapan dan Penawaran (“Permen ESDM 35/2021”).",
    "duration": "dibaca 5 menit",
    "author": "oleh Aisha Adelia",
    "slug": "permen-esdm-35-2021-nafas-baru-ketentuan-penetapan-dan-penawaran-wilayah-kerja-minyak-dan-gas-bumi",
    "text": [
        `<p><a href="https://www.hukumonline.com/pusatdata/detail/lt61e78f1c07544">Peraturan Menteri ESDM Nomor 35 Tahun 2021</a> tentang Tata Cara Penetapan dan Penawaran (“Permen ESDM 35/2021”). Peraturan baru ini diharapkan dapat mengoptimalkan pengusahaan wilayah kerja migas dan menyederhanakan ketentuan mengenai penetapan dan penawaran wilayah kerja.<sup>1</sup></p>`,
        `<p>Sebelumnya, ketentuan mengenai penetapan dan penawaran wilayah kerja diatur dalam beberapa peraturan yang berbeda, yaitu:<sup>2</sup></p>
          <ol>
            <li><a style="color: #1155CC; text-decoration: underline; font-weight: bold;" href="https://www.hukumonline.com/pusatdata/detail/lt4f30deabd2975">Peraturan Menteri ESDM Nomor 5 Tahun 2012 </a> (“Permen ESDM 5/2012”), yang membahas mengenai penetapan dan penawaran wilayah kerja nonkonvensional;</li>
            <li><a style="color: #1155CC; text-decoration: underline; font-weight: bold;" href="https://www.hukumonline.com/pusatdata/detail/28592">Peraturan Menteri ESDM Nomor 35 Tahun 2008 </a>(“Permen ESDM 35/2008”), yang membahas mengenai penetapan dan penawaran wilayah kerja konvensional; dan</li>
            <li><a style="color: #1155CC; text-decoration: underline; font-weight: bold;" href="https://www.hukumonline.com/pusatdata/detail/28593">Peraturan Menteri ESDM Nomor 36 Tahun 2008 </a>(“Permen ESDM 36/2008”), yang membahas mengenai penetapan dan penawaran wilayah kerja gas metana batubara.</li>
          </ol>
          <p>Dengan terbitnya Permen ESDM 35/2021, ketiga peraturan di atas dicabut dan ketentuan mengenai penetapan dan penawaran wilayah kerja konvensional dan nonkonvensional (yang juga termasuk gas metana batubara) dijadikan satu dalam peraturan baru tersebut.</p>
        `,
        `<p>Secara umum, Permen ESDM 35/2021 mengatur beberapa aspek terkait wilayah kerja minyak dan gas bumi konvensional dan nonkonvensional (“Wilayah Kerja”), antara lain kriteria, penyiapan, penetapan, dan penawaran.<sup>3</sup> Adapun hal tersebut dilakukan melalui pertimbangan teknis, ekonomis, tingkat risiko, dan efisiensi, serta berasaskan keterbukaan, keadilan, akuntabilitas, dan persaingan usaha yang wajar.<sup>4</sup></p>`,
        `
          <p><b>Kriteria Wilayah Kerja</b></p>
          <p>Wilayah Kerja disiapkan oleh Menteri ESDM melalui Direktur Jenderal Minyak dan Gas Bumi (“Dirjen Migas”) dari wilayah terbuka, yang kriterianya meliputi:<sup>5</sup></p>
          <ol>
            <li>wilayah yang belum pernah ditetapkan sebagai Wilayah Kerja;</li>
            <li>sebagian atau seluruh Wilayah Kerja yang dikembalikan oleh kontraktor berdasarkan kontrak kerja sama</li>
            <li>Wilayah Kerja yang telah memasuki masa produksi, tetapi jangka waktu kontrak kerja samanya berakhir;</li>
            <li>bagian Wilayah Kerja yang dikembalikan atas usul kontraktor, yang belum pernah dikembangkan, sedang diproduksikan, dan/atau pernah diproduksikan;</li>
            <li>bagian Wilayah Kerja yang dikembalikan atas permintaan Menteri ESDM, yang belum pernah dikembangkan dan/atau pernah diproduksikan; dan</li>
            <li>Wilayah Kerja available, yang merupakan Wilayah Kerja yang pernah ditawarkan pada lelang reguler atau penawaran langsung yang tidak dapat ditetapkan pemenangnya, dengan masa berlaku selama 6 (enam) bulan sejak penetapan hasil penawaran Wilayah Kerja, termasuk bentuk dan ketentuan pokok kontrak kerja samanya.<sup>6</sup></li>  
          </ol>      
        `,
        `
          <p>Apabila dibandingkan dengan peraturan yang sebelumnya, Permen ESDM 35/2008 tidak memasukkan kriteria nomor 6 di atas, sedangkan Permen ESDM 5/2012 mengatur kriteria wilayah terbuka yang kurang lebih sama.<sup>7</sup></p>
        `,
        `
          <p><b>Penyiapan Wilayah Kerja</b></p>
          <p>Terdapat dua jenis prosedur penyiapan Wilayah Kerja, yang dibedakan berdasarkan bagaimana Wilayah Kerja tersebut akan ditawarkan pada tahap penawaran, yaitu melalui lelang reguler atau penawaran langsung. Prosedur penyiapan Wilayah Kerja untuk penawaran melalui lelang reguler dan penawaran langsung dijelaskan sebagai berikut.</p>
          <ol id="normal-list">
            <li>
              Lelang reguler
              <ol>
                <li>Penyiapan Wilayah Kerja untuk penawaran melalui lelang reguler dilakukan oleh Menteri ESDM melalui Dirjen Migas, atau dapat dilakukan berdasarkan usulan badan usaha (“BU”) atau bentuk usaha tetap (“BUT”).<sup>8</sup> Kedua usulan penetapan Wilayah Kerja ini, baik yang berasal dari Dirjen Migas maupun BU atau BUT, selanjutnya diserahkan oleh Dirjen Migas kepada Menteri ESDM.<sup>9</sup></li>
                <li>Perlu diketahui bahwa Wilayah Kerja harus memenuhi ketentuan luas, yaitu: 1) 8.500 km2 untuk wilayah lepas pantai; 2) 5.500 km2 untuk wilayah daratan; atau 3) dapat ditetapkan lain berdasarkan data teknis.<sup>10</sup></li>
              </ol>
            </li>
            <li>
              Penawaran langsung
              <ol>
                <li>Penyiapan Wilayah Kerja untuk penawaran langsung dilakukan oleh Menteri ESDM melalui Dirjen Migas berdasarkan usulan dari BU atau BUT.<sup>11</sup> Usulan ini hanya dapat diajukan terhadap wilayah terbuka yang tidak dicadangkan dalam lelang reguler.<sup>12</sup></li>
                <li>Usulan penetapan Wilayah Kerja selanjutnya dilaksanakan melalui studi bersama, dengan pengecualian Wilayah Kerja available yang tidak diwajibkan untuk dilaksanakan melalui studi bersama.<sup>13</sup> Studi bersama harus mendapatkan persetujuan dari Menteri ESDM mengenai pengembalian atau pengakhiran untuk beberapa kriteria wilayah terbuka, yaitu: 1) sebagian atau seluruh Wilayah Kerja yang dikembalikan oleh kontraktor berdasarkan kontrak kerja sama; 2) Wilayah Kerja yang telah memasuki masa produksi, tetapi jangka waktu kontrak kerja samanya berakhir; 3) bagian Wilayah Kerja yang dikembalikan atas usul kontraktor, yang belum pernah dikembangkan, sedang diproduksikan, dan/atau pernah diproduksikan; dan 4) bagian Wilayah Kerja yang dikembalikan atas permintaan Menteri ESDM, yang belum pernah dikembangkan dan/atau pernah diproduksikan.<sup>14</sup> Jenis usulan di poin (3) dan (4) hanya dapat diajukan oleh BU.<sup>15</sup></li>
                <li>Perlu diketahui bahwa area yang diusulkan tidak boleh melebihi 11.000 km<sup>2</sup>.<sup>16</sup></li>
              </ol>
            </li>
          </ol>
        `,
        `
          <p><b>Penetapan Wilayah Kerja</b></p>
          <p>Berdasarkan usulan penetapan Wilayah Kerja yang diajukan oleh Dirjen Migas pada tahap penyiapan, Menteri ESDM kemudian memutuskan untuk memberikan persetujuan atau penolakan atas usulan tersebut.<sup>17</sup> Dalam hal Menteri ESDM menyetujui usulan penetapan Wilayah Kerja, persetujuan ini akan dilanjutkan dengan penawaran kontrak kerja sama kepada BU atau BUT.<sup>18</sup</p>
          
          <p>Apabila dibandingkan dengan peraturan yang sebelumnya, Permen ESDM 35/2008 dan Permen ESDM 5/2012 mengatur bahwa sebelum menetapkan Wilayah Kerja, Menteri ESDM berkonsultasi kepada gubernur untuk memperoleh informasi terkait wilayah tertentu yang memiliki potensi, sedangkan dalam Permen ESDM 35/2021 tidak terdapat ketentuan yang demikian.<sup>19</sup></p>
        `,
        `
          <p><b>Penawaran Wilayah Kerja</b></p>
          <p>Pada prinsipnya, penawaran Wilayah Kerja dilakukan melalui lelang reguler atau lelang penawaran langsung.<sup>20</sup> Perlu diketahui bahwa proses lelang yang diatur dalam Permen ESDM 35/2021 dilaksanakan secara elektronik.<sup>21</sup> Hal ini berbeda dengan Permen ESDM 35/2008 dan Permen 5/2012, di mana kedua peraturan tersebut tidak secara eksplisit mengatur mengenai pelaksanaan lelang secara elektronik.</p>
        `,
        `
          <p>Baik lelang reguler maupun lelang penawaran langsung dilaksanakan dengan prosedur sebagai berikut.</p>
          <ol>
            <li>
              Penerbitan dokumen lelang <br>
              Dirjen Migas menyiapkan dan menerbitkan dokumen lelang untuk setiap Wilayah Kerja yang ditawarkan.<sup>22</sup>
            </li>
            <li>
              Pembelian dokumen lelang <br>
              BU atau BUT yang merupakan calon peserta penawaran Wilayah Kerja wajib membeli dokumen lelang sesuai dengan 
              Wilayah Kerja yang diminati.<sup>23</sup> Setelah membeli dokumen lelang, BU atau BUT dicatat sebagai calon peserta lelang reguler atau lelang penawaran langsung.<sup>24</sup>
            </li>
            <li>
              Penyerahan dokumen partisipasi dan jaminan penawaran <br>
              Calon peserta lelang yang meneruskan keikutsertaannya sebagai peserta wajib menyerahkan dokumen partisipasi kepada tim penawaran.<sup>25</sup> Bersamaan dengan 
              dokumen partisipasi, peserta lelang juga wajib menyerahkan jaminan penawaran, yang akan dikembalikan apabila peserta tidak ditetapkan sebagai pemenang.<sup>26</sup>
            </li>
            <li>
              Pemeriksaan dan penilaian dokumen partisipasi <br>           
              Dokumen partisipasi pada pelaksanaan lelang reguler selanjutnya diperiksa dan dinilai oleh tim lelang, sedangkan dokumen partisipasi pada pelaksanaan lelang penawaran langsung selanjutnya diperiksa dan dinilai oleh tim penilai.<sup>27</sup>
            </li>
            <li>
              Penetapan pemenang lelang <br>
              Berdasarkan hasil pemeriksaan dan penilaian yang dilakukan oleh tim lelang atau tim penilai, Menteri ESDM melalui Dirjen Migas menetapkan dan mengumumkan pemenang lelang reguler atau lelang penawaran langsung, baik melalui studi bersama maupun tanpa melalui studi bersama.<sup>28</sup>
            </li>
            <li>
              Penyampaian surat kesanggupan <br>
              Pemenang lelang wajib menyampaikan surat kesanggupan untuk memenuhi seluruh komitmen dalam dokumen partisipasi, termasuk persetujuan konsep kontrak kerja sama, kepada Dirjen Migas.<sup>29</sup>
            </li>
            <li>
              Penandatanganan kontrak kerja sama <br>
              Berdasarkan surat kesanggupan yang disampaikan, Dirjen Migas meminta pemenang lelang untuk menyampaikan dokumen persyaratan penandatanganan kontrak kerja sama.<sup>30</sup> Penandatanganan kontrak kerja sama dilakukan paling lambat 60 (enam puluh) hari kalender sejak pengumuman pemenang lelang.<sup>31</sup>
            </li>
            <li>
              Penyerahan jaminan pelaksanaan <br>
              Pemenang lelang wajib menyerahkan jaminan pelaksanaan kepada Dirjen Migas paling lambat pada saat penandatanganan kerja sama.<sup>32</sup>
            </li>
          </ol>

          <p><i>
            Ada sejumlah persyaratan yang wajib dipenuhi peserta dalam lelang wilayah kerja migas. Untuk memudahkan perusahaan dalam memenuhi persyaratan tersebut, Hukumonline mempersembahkan Regulatory Compliance System (RCS).
          </i></p>

          <p>
            <i>RCS dilengkapi sejumlah fitur yang memudahkan proses pemantauan kepatuhan hukum. Di antaranya, <b>obligation checklist</b> yang memungkinkan pendataan kewajiban hukum dilakukan lebih cepat.</i>
          </p>

          <p>
            <i>Saatnya permudah pemantauan kepatuhan hukum perusahaan dengan RCS. Klik <a href="https://rcs.hukumonline.com/?utm_source=website&utm_medium=seo_articles&utm_campaign=cta_kerjamigas" style="color: #1155CC">di sini</a> untuk menjadwalkan demo <b>GRATIS!</b></i>
          </p>


          <ul>   
            <p style="font-size: 12px;">1. Bagian "Menimbang",Permen ESDM 35/2021.</p>
            <p style="font-size: 12px;">2. Pasal 68,Permen ESDM 35/2021.</p>
            <p style="font-size: 12px;">3. Pasal 2(2) dan 3(1),Permen ESDM 35/2021.</p>
            <p style="font-size: 12px;">4. Pasal 3(2),Permen ESDM 35/2021.</p>
            <p style="font-size: 12px;">5. Pasal 4(1-2),Permen ESDM 35/2021.</p>
            <p style="font-size: 12px;">6. Pasal 1 (8),Permen ESDM 35/2008</p>
            <p style="font-size: 12px;">7. Pasal 4 (2),Permen ESDM 35/2008 dan Pasal 15 (2),Permen ESDM 5/2012</p>
            <p style="font-size: 12px;">8. Pasal 5 (1-2),Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">9. Pasal 5 (8),Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">10. Pasal 5 (6),Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">11. Pasal 6 (1),Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">12. Pasal 6 (2),Permen ESDM 35/2008</p>
            <p style="font-size: 12px;">13. Pasal 6 (3-5),Permen ESDM 35/2008</p>
            <p style="font-size: 12px;">14. Pasal 6 (6), Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">15. Pasal 6 (7), Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">16. Pasal 7 (1a), Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">17. Pasal 24 (1), Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">18. Pasal 24 (2), Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">19. Pasal 22, Permen ESDM 35/2008 dan Pasal 34, Permen ESDM 5/2012</p>
            <p style="font-size: 12px;">20. Pasal 26, Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">21. Pasal 29, Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">22. Pasal 30 (1), Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">23. Pasal 31 (1), Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">24. Pasal 31 (2), Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">25. Pasal 32 (1), Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">26. Pasal 43 (1) dan (9), Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">27. Pasal 35 (1),36 (1), 37(1) dan 38 (1), Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">28. Pasal 49 (1),49 (4),51 (1),51 (4),55 (1),55 (4) dan 57 (1), Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">29. Pasal 50 (2), 53 (2), dan 56 (2),Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">30. Pasal 57 (2),Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">31. Pasal 57 (4),Permen ESDM 35/2021</p>
            <p style="font-size: 12px;">32. Pasal 44 (1) dan (4),Permen ESDM 35/2021</p>
          </ul>
        `
    ],
    "description": "Menjelang akhir Desember 2021, Menteri Energi dan Sumber Daya Mineral (“Menteri ESDM”) merevisi aturan mengenai tata cara penetapan dan penawaran wilayah kerja minyak dan gas bumi dengan menerbitkan"
  },
  {
    "article_date": "03-04-2022",
    "img": "/assets/articles/article_home_3.jpeg",
    "meta_img":"https://drive.google.com/uc?export=view&id=1pD1FGbwugj8nokg7QrQPRCaIx2oQJZXP",
    "title": "PROPER: Manifestasi Transparansi Pengelolaan Lingkungan Hidup di Indonesia",
    "date": "04 Mar 2022",
    "date_2": "04 Mar 2022",
    "description_home": "Aktivitas bisnis perusahaan acapkali berdampak pada eksploitasi sumber daya alam (SDA) bahkan menimbulkan pencemaran bagi lingkungan hidup.",
    "author": "oleh Aditiya Putra",
    "duration": "dibaca 5 menit",
    "slug": "proper-manifestasi-tranparansi-pengelolaan-lingkungan-hidup-di-indonesia",
    "description": "Aktivitas bisnis perusahaan acapkali berdampak pada eksploitasi sumber daya alam (SDA) bahkan menimbulkan pencemaran bagi lingkungan hidup.",
    "text": [
      `<p>Hal demikian tentunya mendorong pemerintah untuk mengambil sikap tegas dalam upaya peningkatan pengawasan terhadap korporasi terkait pengelolaan lingkungan hidup. Sebagai upaya konkret atas hal tersebut, pemerintah kemudian memperkenalkan Proper (Program Penilaian Peringkat Kinerja Perusahaan).</p>`,
      `<p>Jauh sebelum diimplementasikan, Proper sudah diinisiasi sejak tahun 1995. Sebelum, sebagai upaya pengelolaan lingkungan hidup, pemerintah telah terlebih dahulu mengimplementasikan Prokasih (program kali bersih). Prokasih adalah  program kerja pengendalian pencemaran air sungai dengan tujuan untuk meningkatkan kualitas air sungai agar tetap berfungsi sesuai dengan peruntukannya.</p>`,
      `
        <p><b>Apa itu Proper?</b></p>
        <p>Proper berdasarkan <a href="https://www.hukumonline.com/pusatdata/detail/lt6029fee643e87/peraturan-menteri-lingkungan-hidup-dan-kehutanan-nomor-1-tahun-2021">Peraturan Menteri Lingkungan Hidup Dan Kehutanan Nomor 1 Tahun 2021 </a> (“Permen LHK 1/2021”), Proper artinya evaluasi kinerja penanggung jawab usaha dan/atau kegiatan di bidang pengelolaan lingkungan hidup.<sup>1</sup>
        Secara sederhana, Proper adalah wujud transparansi dan demokratisasi dalam pengelolaan lingkungan hidup di Indonesia.</p>

        <p>Prinsipnya tanggung jawab pelaksanaan Proper berada pada Kementerian Lingkungan Hidup melalui pelaksana yang terdiri dari Dewan Pertimbangan dan tim teknis Proper.<sup>2</sup> Tim teknis inilah yang esensinya melaksanakan tugas strategis, mulai dari penapisan usaha dan/atau kegiatan peserta, melakukan supervisi hasil penilaian kinerja penanggung jawab usaha dan/atau kegiatan peserta, sampai kepada tugas melakukan evaluasi terhadap pemeringkatan kinerja peserta.<sup>3</sup></p>
      `,
      `
        <p><b>Kriteria Perusahaan Sebagai Peserta Proper</b></p>
        <p>Perusahaan yang memenuhi kriteria sebagai peserta Proper adalah sebagai berikut:<sup>4</sup></p>
          <ol>
            <li>Memiliki persetujuan lingkungan yang terdaftar di SIMPEL; </li>
            <li>
              Usaha atau kegiatan perusahaan memenuhi sejumlah kriteria, antara lain:
              <ol>
                <li>hasil produknya untuk tujuan ekspor;</li>
                <li>terdapat dalam pasar bursa;</li>
                <li>menjadi perhatian masyarakat, baik dalam lingkup regional maupun nasional; <br/>dan/atau</li>
                <li>skala kegiatan signifikan untuk menimbulkan dampak terhadap lingkungan hidup.</li>
              </ol>
            </li>
            <li>Usaha atau kegiatan tersebut tidak dalam pengenaan sanksi administratif.</li>
          </ol>  
      `,
      `
        <p>Sebagai peserta, akan dilakukan penapisan usaha (screening) terhadap perusahaan dan atau kegiatan oleh tim teknis yang nantinya menjadi bagian dari proses penilaian Proper.</p>
      `,
      `<p><b>Hal-hal yang Dinilai dalam Proses Proper</b></p>`,
      `table 1`,
      `
        <p><b>Peringkat Proper</b></p>
        <p>Pemeringkatan merupakan tahapan paling akhir dalam pelaksanaan proper. Pemeringkatan ini dilakukan dalam tiga tahap, yaitu tahapan pemeringkatan sementara, kemudian dilanjutkan dengan sanggahan dan klarifikasi dan terakhir ialah pemeringkatan akhir.<sup>5</sup></p>
        <p>Pemeringkatan Proper secara garis besar adalah sebagai berikut:</p>
      `,
      `
        <ol>
          <li><b>Emas</b>, merupakan peringkat tertinggi mengingat memenuhi seluruh aspek penilaian proper termasuk penilaian tahap III.<sup>6</sup></li>
          <li><b>Hijau</b>, merupakan peringkat kedua ketika perusahaan setidaknya memenuhi hasil penilaian proper hingga ke tahap II.<sup>7</sup> </li>
          <li><b>Biru</b>, untuk peserta Proper yang telah melakukan upaya pengelolaan lingkungan hidup sesuai dengan ketentuan peraturan perundang-undangan. Peraih peringkat biru juga termasuk peserta Proper yang lolos hingga tahap II namun hasil berada dibawah 25 (dua puluh lima) persentil.<sup>8</sup></li>
          <li><b>Merah</b>, untuk peserta Proper yang upaya pengelolaan lingkungan hidupnya dilakukan tidak sesuai dengan ketentuan peraturan perundang undangan.<sup>9</sup></li>
          <li><b>Hitam</b>, untuk peserta Proper yang melakukan perbuatan atau kelalaian yang menyebabkan terjadinya pencemaran dan/atau kerusakan lingkungan.<sup>10</sup></li>
        </ol>  
      `,
      `
        <p>Berdasarkan pemeringkatan di atas, hal ini kemudian berkorelasi dengan masih sedikitnya perusahaan untuk bisa mencapai peringkat emas apabila kita merujuk Keputusan Menteri Lingkungan Hidup Dan Kehutanan Republik Indonesia Nomor Sk.1307/Menlhk/Setjen/Kum.1/12/2021 Tentang Hasil Penilaian Peringkat Kinerja Perusahaan Dalam Pengelolaan Lingkungan Hidup Tahun 2020 - 2021 (“Kepmen LHK Sk.1307/2021”), sebagai berikut:<sup>11</sup></p>
        <ol>
          <li>Peringkat emas sebanyak: 47 Perusahaan</li>
          <li>Peringkat hijau sebanyak 186 Perusahaan</li>
          <li>Peringkat biru sebanyak 1670 Perusahaan</li>
          <li>Peringkat Merah sebanyak 645 Perusahaan</li>
          <li>Peringkat ditangguhkan sebanyak 2 Perusahaan</li>
        </ol>
      `,
      `
        <p><b>Konsekuensi sebagai Peserta Proper</b></p>
        <p>Penetapan peringkat Proper kemudian akan menjadi dasar bagi Menteri untuk melakukan tindak lanjut dalam tiga bentuk yang berbeda, yaitu dapat berupa reward, pembinaan atau justru penegakan hukum.<sup>12</sup></p>
      `,
      `table 2`,
      `
        <p>Perlu untuk diberikan catatan, bahwa tindak lanjut berupa penegakan hukum yang dilakukan Kementerian untuk peserta Proper dengan <b>peringkat merah</b> yang sekalipun sebelumnya telah dilakukan pembinaan, namun tetap tidak taat; dan peserta <b>Proper dengan peringkat hitam</b>, bukan semata-mata merupakan upaya untuk melakukan pemulihan dan pengelolaan lingkungan hidup semata. Akan tetapi, bertujuan juga untuk memberikan pemulihan bagi perusahaan itu sendiri. Pasalnya, regulasi pada akhirnya memberikan kesempatan bagi peserta untuk diikutsertakan kembali ke dalam pelaksanaan Proper, dengan syarat telah menyelesaikan pelaksanaan penegakan hukum.<sup>13</sup></p>
        
        <p><i>
        Penilaian Proper dipengaruhi oleh tingkat kepatuhan hukum perusahaan terhadap regulasi lingkungan hidup. Untuk memudahkan perusahaan dalam memenuhi kewajiban hukum lingkungannya, Hukumonline mempersembahkan Regulatory Compliance System (RCS).
        </i></p>

        <p>
          <i>RCS dilengkapi sejumlah fitur yang memudahkan proses pemantauan kepatuhan hukum. Di antaranya, <b>obligation checklist</b> yang memungkinkan pendataan kewajiban hukum dilakukan lebih cepat.</i>
        </p>

        <p>
          <i>Saatnya permudah pemantauan kepatuhan hukum perusahaan dengan RCS. Klik <a href="https://rcs.hukumonline.com/?utm_source=website&utm_medium=seo_articles&utm_campaign=cta_proper" style="color: #1155CC">di sini</a> untuk menjadwalkan demo <b>GRATIS!</b></i>
        </p>
        
        <ul>
            <p style="font-size: 12px;" id="list-content">1. Pasal 1 angka 1, Permen LHK 1/2021.</p>
            <p style="font-size: 12px;" id="list-content">2. Pasal 4, Permen LHK 1/2021.</p>
            <p style="font-size: 12px;" id="list-content">3. Pasal 6 ayat (2), Permen LHK 1/2021.</p>
            <p style="font-size: 12px;" id="list-content">4. Pasal 11, Permen LHK 1/2021.</p>
            <p style="font-size: 12px;" id="list-content">5. Pasal 32, Permen LHK 1/2021.</p>
            <p style="font-size: 12px;" id="list-content">6. Pasal 39 ayat (2), Permen LHK 1/2021.</p>
            <p style="font-size: 12px;" id="list-content">7. <i>Ibid</i>.</p>
            <p style="font-size: 12px;" id="list-content">8. Pasal 33 ayat (2) dan Pasal 39 ayat (2), Permen LHK 1/2021.</p>
            <p style="font-size: 12px;" id="list-content">9. Pasal 33 ayat (2), Permen LHK 1/2021.</p>
            <p style="font-size: 12px;" id="list-content">10. <i>Ibid</i>.</p>
            <p style="font-size: 12px;" id="list-content">11. Kepmen LHK Sk.1307/2021.</p>
            <p style="font-size: 12px;" id="list-content">12. Pasal 45, Permen LHK 1/2021.</p>
            <p style="font-size: 12px;" id="list-content">13. Pasal 49, Permen LHK 1/2021.</p>
        </ul>
      `
    ]
  },
  {
    "article_date": "01-01-2022",
    "img": "/assets/articles/article_home_4.jpeg",
    "meta_img":"https://drive.google.com/uc?export=view&id=1x5BzU0KtCZuYkSMfuZipfTWsgelSeGtA",
    "title": "Optimis Capai 23% Penurunan Emisi Tahun 2025, Ini Peluang dan Tantangan yang Dihadapi Kementerian ESDM RI dan Pertamina Geothermal Energi",
    "date": "01 Jun 2022",
    "date_2": "01 Jun 2022",
    "description_home": "Adanya peningkatan dampak perubahan iklim akhir - akhir ini, membuat Indonesia berupaya berpartisipasi dalam mengurangi dampak perubahan iklim dunia dengan menetapkan target Bauran Energi Baru dan Terbarukan (EBT) sebesar 23% pada tahun 2025.",
    "author": "oleh Mochamad Januar Rizki",
    "duration": "dibaca 3 menit",
    "slug": "optimis-capai-23-persen-penurunan-emisi-tahun-2025-ini-peluang-dan-tantangan-yang-dihadapi-kementerian-ESDM-RI-dan-pertamina-geothermal-energi",
    "description": "Adanya peningkatan dampak perubahan iklim akhir - akhir ini, membuat Indonesia berupaya berpartisipasi dalam mengurangi dampak perubahan iklim dunia dengan menetapkan target Bauran Energi Baru dan Terbarukan (EBT) sebesar 23% pada tahun 2025.",
    "text": [
      `<p>Ada berbagai persiapan dan komitmen dibutuhkan agar bauran EBT tersebuat dapat terealisasi dengan baik. Jadi bagaimana kesiapan dan komitmen pemerintah dalam mencapai 23% Bauran EBT di tahun 2025?</p>`,
      `<p>Rasa ingin tahu serta untuk menjawab pertanyaan tersebut, Hukumonline baru saja menggelar webinar “Peluang dan Tantangan Menuju Bauran Energi Baru dan Terbarukan 23% 2025” pada Senin, 30 Mei 2022 lalu. Pada webinar kali ini hadir beberapa pemateri handal yang dapat memaparkan persiapan Indonesia mengejar target tersebut yaitu Direktur Panas Bumi Kementerian Energi dan Sumber Daya Mineral, Harris Yahya dan Lahendong Manager Planning and Engineering PT Pertamina Geothermal Energy, Jati Permana Kurniawan.</p>`,
      `<p>Dalam materi yang disampaikan, Haris memaparkan terdapat kondisi yang mendorong penerapan bauran EBT. Penyebabnya yaitu akibat fluktuasi harga dan suplai batubara dan gas alam, dunia saat ini mengalami krisis energi. Turunnya suplai batubara domestik di awal tahun 2022 berdampak pada terganggunya pasokan listrik PLTU batubara dan pemberlakuan larangan ekspor sementara. Selain itu, Haris menjelaskan emisi karbon dioksida yang dihasilkan pun semakin besar. Menurut data IEA sebesar 33 Gt CO2 dilepas ke atmosfer di tahun 2021.</p>`,
      `<p>Dalam kondisi berbeda, cadangan bahan bakar dalam negeri yang bersifat operasional hanya cukup untuk 20–23 hari dan tidak ada cadangan penyangga. Cadangan minyak bumi Indonesia 2020 sebesar 4,17 miliar barrel atau 9,5 tahun produksi. Sedangkan, neraca perdagangan migas 2021 defisit sebesar US$ 13,25 miliar. “Sumber daya EBT dengan potensi lebih dari 3.000 GW menjadi opsi menjaga pasokan energi sekaligus mengurangi emisi gas rumah kaca yang menyebabkan perubahan iklim, dan dapat mengurangi defisit perdagangan,” ungkap Haris.</p>`,
      `<p>Penjelasan lebih lanjut, Haris menyatakan pemanfaatan EBT saat ini hanya 0,3 persen dari total potensi. Padahal, Indonesia memiliki sumber daya EBT yang besar, bervariasi, dan tersebar. Potensi tersebut antara lain energi hidro yang tersebar di seluruh wilayah Indonesia, terutama di Kaltara, NAD, Sumbar, Sumut, dan Papua. Selanjutnya, energi surya tersebar di seluruh wilayah Indonesia, terutama di NTT, Kalbar, dan Riau memiliki radiasi lebih tinggi. Energi angin (>6 m/s) terutama terdapat di NTT, Kalsel, Jabar, Sulsel, NAD dan Papua. Energi laut tersebar di seluruh wilayah Indonesia, terutama Maluku, NTT, NTB dan Bali. Dan, energi panas bumi tersebar pada kawasan ring of fire, meliputi Sumatera, Jawa, Bali, Nusa Tenggara, Sulawesi, dan Maluku.</p>`,
      `<p>Haris selanjutnya menambahkan, pemerintah punya berbagai program untuk mendorong EBT antara lain insentif pajak seperti pada panas bumi. Selain itu, pemerintah juga menyiapkan kepastian harga pada EBT. Adanya aspek keselamatan kerja juga mendorong pemberi perlindungan pada SDM di sektor EBT termasuk panas bumi.</p>`,
      `<p>Jati juga menjelaskan pengembangan EBT khususnya pada panas bumi harusnya dapat lebih tinggi produksinya dibanding kondisi saat ini. Biaya infrastruktur adalah salah satu tantangan karena sumber energi panas bumi yang berada pada wilayah-wilayah terpencil. Investasi yang tinggi sehingga butuh komitmen yang kuat bagi investor. Namun, sudah ada lembaga keuangan yang bersedia menanggung risiko pada eksplorasi energi panas bumi.</p>`,
      `<p>“Bila ditinjau dari sisi keekonomian karena tarifnya masih harus bersaing dengan fosil fuel. Pemanfaatan EBT (panas bumi) harus dihargai lebih dibanding fosil fuel karena dapat manfaat lebih juga,” harap Jati.</p>`,
      `<p>Jati mengungkapkan secara optimis bahwa Indonesia memiliki peluang besar untuk menjadi yang nomor 1 dalam hal kapasitas terpasang panas bumi. Begitu juga dengan Pertamina (melalui PGE) untuk menjadi perusahaan panas bumi terbesar di dunia. Sehingga, diperlukan terobosan yang memberikan insentif bagi pengembangan panas bumi untuk mencapai target bauran energi 2025.</p>`,
      `<p>Kita perlu optimis ada peluang besar untuk memaksimalkan pemanfaatan panas bumi melalui aplikasi langsung. Investasi panas bumi membutuhkan lebih banyak akses ke pembiayaan hijau, serta pelanggan energi hijau. Skema Feed-in-Tariff adalah instrumen ekonomi paling populer yang digunakan di lebih dari 50 negara untuk mempromosikan investasi sumber energi terbarukan. “Ini terbukti secara empiris sangat mudah beradaptasi dan efektif, di keduanya negara maju dan negara berkembang,” papar Jati.</p>`,
      `<p>Keberadaan pasar yang kompetitif telah memimpin dalam pengembangan energi terbarukan. Melalui pasar terbuka, pemerintah dapat menerapkan Renewable Portfolio Standard (RPS) yang telah berhasil peningkatan porsi energi terbarukan di banyak negara.</p>`,
      `<p>Ada banyak regulasi yang tentunya menjadi konsentrasi kita di kemudian hari terhadap aturan - aturan iklim dan lingkungan mengenai energi terbarukan. Salah satu yang dapat membantu anda untuk mengetahui secara cepat dan realtime mengenai pembaruan regulasi adalah dengan Regulatory Compliance System. Sebuah sistem yang dapat menjadi solusi pemantauan kepatuhan hukum perusahaan berbasis kecerdasan buatan (AI). Anda dapat bisa mendapatkan demo request secara gratis <a href="https://rcs.hukumonline.com/" style="color: #1155CC">disini</a> untuk mendapatkan kemudahan pemantauan kepatuhan perusahaan Anda terhadap perubahan perundang-undangan secara tepat dan terintegrasi lebih cepat.</p>`,
    
    ]
  },
];

data.sort((next: any, current: any) => {
  const nextDate: any = new Date(current.date);
  const currentDate: any = new Date(next.date);
  return nextDate - currentDate
})

export default data;